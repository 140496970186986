<template>
  <div class="timeline-container">
    <div class="date-from">
      {{ liveMode ? 'Live' : formatedTimeRange.dateFrom }}
    </div>
    <div ref="dataVisualizer"
         :style="dataVisualizerStyle"
         :class="dataVisualizerClass"
         class="data-visualizer"
         @mousedown.self="clickAndDrag">
      <div v-if="!loading && !noData && !liveMode"
           ref="cursor"
           class="cursor"
           @mouseup="cursorDown = false"
           @mousedown="cursorDown = true"
           @mouseenter="displayCursorInfo"
           @mouseout="delayCursorInfoHide(cursorInfoDelay)">
        <CursorInfo v-if="showCursorInfo || cursorDown"
                    :time="getTimeFromCursorPosition(data, cursorPosition)"
                    :mean="getCurrentMean(data, cursorPosition)" />
      </div>
      <FocusPin v-for="(pin, idx) in focusPinList"
                :key="idx"
                :positionX="pin.positionX"
                class="focus-pin" />
      <q-spinner-dots v-if="loading"
                      size="30px"
                      color="black"
                      class="timeline__spinner" />
      <span v-if="noData && !loading && (mean === undefined || mean === null)"
            class="timeline__text">
        {{ emptySelection ? $t('components.timeline.barText.selectBuilding'): (noDevices ? $t('components.timeline.barText.noDevice') : $t('components.timeline.barText.noData')) }}
      </span>
    </div>
    <div class="date-to">
      {{ liveMode ? 'Live' :formatedTimeRange.dateTo }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import chroma from 'chroma-js';
import moment from 'moment';
import DebounceMixin from '@/app/mixins/debounce.js';
import CursorInfo from '@/app/views/building-viewer/components/cursor-info.vue';
import FocusPin from '@/app/components/ui/focus-pin.vue';

export default {
  name: 'Timeline',
  components: {
    CursorInfo,
    FocusPin,
  },
  mixins: [DebounceMixin],
  props: {
    timeRange: {
      type: Object,
      default: () => ({ dateFrom: 'start date', dateTo: 'end date' }),
    },
    data: {
      type: Array,
      default: () => [],
    },
    limits: {
      type: Object,
      default: () => ({ min: 0, max: 0 }),
    },
    threshold: {
      type: Object,
      default: () => ({ min: 0, max: 0 }),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    liveMode: {
      type: Boolean,
      default: false,
    },
    mean: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      cursorDown: false,
      cursorPosition: 0,
      dateFormat: 'ddd D MMM YYYY',
      showCursorInfo: false,
      cursorInfoDelay: 1000,
      focusPinList: [],
      units: {
        air: '',
        parking: '%',
        temperature: '°C',
        sound: 'dB',
        co2: 'ppm',
        cov: '%',
        humidity: '%',
      },
    };
  },
  computed: {
    ...mapState('ui', ['colorScales']),
    currentOapp() {
      return this.$store.state.oapp.currentOapp;
    },
    currentDevices() {
      return this.$store.getters['selections/devices'];
    },
    filteredSelection() {
      return this.$store.getters['selections/filteredBuildings'];
    },
    dataVisualizerStyle() {
      const gradient = this.createGradient(this.data);
      const hasData = !!this.data.length;
      let backgroundValue = 'white';

      if (this.loading || this.emptySelection || this.noDevices) backgroundValue = 'white';
      else if (!hasData && !this.liveMode && !this.mean) backgroundValue = `url('/assets/ui/PNG/trame.jpg')`;
      else if (!this.liveMode && hasData) backgroundValue = `linear-gradient(to right, ${gradient}), url('/assets/ui/PNG/trame.jpg')`;
      else {
        const meanColor = this.createMeanColor(this.mean);
        backgroundValue = meanColor || `url('/assets/ui/PNG/trame.jpg')`;
      }
      return { background: backgroundValue };
    },
    dataVisualizerClass() {
      return {
        'data-visualizer--loading': this.loading || this.emptySelection,
        'data-visualizer--livemode': this.liveMode,
      };
    },
    formatedTimeRange() {
      return {
        dateFrom: moment(this.timeRange.dateFrom).format(this.dateFormat),
        dateTo: moment(this.timeRange.dateTo).format(this.dateFormat),
      };
    },
    currentColorScale() {
      return this.colorScales[this.currentOapp.type];
    },
    noData() {
      return this.data.length <= 0;
    },
    emptySelection() {
      return Object.keys(this.filteredSelection).length === 0;
    },
    noDevices() {
      return this.currentDevices.length === 0;
    },
  },
  watch: {
    cursorPosition() {
      this.$refs.cursor.style.left = `${this.cursorPosition}px`;
      this.$store.commit('selections/setTimeline', this.getTimeFromCursorPosition(this.data, this.cursorPosition));
    },
    cursorDown() {
      document.body.style.cursor = this.cursorDown ? 'grabbing !important' : 'default';
    },
    threshold() {
      this.focusPinList = this.createFocusPin(this.data);
    },
    data() {
      this.$store.commit('selections/setTimeline', this.getTimeFromCursorPosition(this.data, this.cursorPosition));
    },
  },
  mounted() {
    this.$parent.$el.addEventListener('mouseup', evt => (this.cursorDown = false));
    this.$parent.$el.addEventListener('mouseleave', evt => (this.cursorDown = false));
    this.$parent.$el.addEventListener('mousemove', evt => this.updateCursorPosition(evt, 'drag'));
    this.focusPinList = this.createFocusPin(this.data);
    this.$store.commit('selections/setTimeline', this.getTimeFromCursorPosition(this.data, this.cursorPosition));
  },
  methods: {
    createFocusPin(data) {
      if (this.threshold.min === 0 && this.threshold.max === 0) return [];
      const intervalSizePx = this.$refs.dataVisualizer.offsetWidth / data.length;
      return data.reduce((acc, d, idx) => {
        if (d[1] >= this.threshold.max || d[1] <= this.threshold.min) {
          acc.push({
            positionX: intervalSizePx * idx + intervalSizePx / 2,
          });
        }
        return acc;
      }, []);
    },
    clickAndDrag(evt) {
      if (this.loading || this.noData || this.liveMode) return;
      this.cursorDown = true;
      this.updateCursorPosition(evt, 'drag');
    },
    displayCursorInfo(evt) {
      this.clearDebounce();
      this.showCursorInfo = true;
    },
    delayCursorInfoHide(delay) {
      this.debounce(() => (this.showCursorInfo = false), delay);
    },
    getTimeFromCursorPosition(oappDataArray, cursorHorizontalPositionInPixel) {
      if (oappDataArray.length === 0 || !this.$refs.dataVisualizer) return null;
      const oappDataCount = oappDataArray.length;
      const dataIndex = this.getDataIndexFromCursorPosition(cursorHorizontalPositionInPixel, oappDataCount);
      return oappDataArray[Math.floor(dataIndex)][0];
    },
    getCurrentMean(oappDataArray, cursorHorizontalPositionInPixel) {
      if (oappDataArray.length === 0 || !this.$refs.dataVisualizer || !this.currentOapp.id) return null;
      const oappDataCount = oappDataArray.length;
      const dataIndex = this.getDataIndexFromCursorPosition(cursorHorizontalPositionInPixel, oappDataCount);
      const cursorDataValue = oappDataArray[dataIndex][1] ? oappDataArray[dataIndex][1].toFixed(2) : null;
      const oappProfilesArray = Object.keys(this.currentOapp.profiles);

      if (cursorDataValue === null) return null;
      // oapp that display a mean word evaluation; ex: good, bad
      if (this.currentOapp.type === 'good-bad') {
        const limits = this.currentOapp.data.global.limits;
        // remove hardcoded values when oapp object is reworked
        const cursorMeanValue = cursorDataValue < limits.max / 2 ? 'GOOD' : 'BAD';
        return cursorMeanValue;
      }
      // oapp that only have 1 profile and display a mean number value
      if (oappProfilesArray.length === 1) {
        const unit = this.currentOapp.profiles[oappProfilesArray[0]].unit;
        return `${cursorDataValue} ${unit}`;
      }
      return null;
    },
    getDataIndexFromCursorPosition(cursorHorizontalPositionInPixel, oappDataCount) {
      const visualizerSizeInPixel = this.$refs.dataVisualizer.offsetWidth;
      const oneDataSizeInPixel = visualizerSizeInPixel / oappDataCount;
      const res = cursorHorizontalPositionInPixel / oneDataSizeInPixel;
      return Math.floor(res);
    },
    updateCursorPosition(evt, eventType) {
      if (eventType === 'drag' && !this.cursorDown) return;
      const elemPosition = this.$refs.dataVisualizer.getBoundingClientRect();
      let currentMousePositionX = evt.clientX - elemPosition.left;
      currentMousePositionX -= this.$refs.cursor.offsetWidth / 2;
      if (currentMousePositionX < 0 || currentMousePositionX > this.$refs.dataVisualizer.offsetWidth - this.$refs.cursor.offsetWidth)
        return;
      this.cursorPosition = currentMousePositionX;
    },
    createMeanColor(mean) {
      if (mean === null || mean === undefined) return null;
      const colorScale = chroma.scale(this.currentColorScale).domain([this.limits.min, this.limits.max]);
      return colorScale(mean);
    },
    createGradient(data) {
      const colorScale = chroma.scale(this.currentColorScale).domain([this.limits.min, this.limits.max]);
      return data
        .map((d, idx) => {
          const value = d[1];
          const color = value !== null ? colorScale(value) : 'transparent';
          const percentagePlacement = (idx / data.length) * 100;
          return `${color} ${percentagePlacement + 1}%`;
        })
        .join(',');
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

$padding = 4px

.timeline-container
  display flex
  width 100%
  border-radius $border-radius
  background-color black
  box-shadow $shadow-elevation-2
  color white
  user-select none
  .date-from, .date-to
    margin 0 24px
    padding $padding 0
    user-select none
  .data-visualizer
    position relative
    flex 2
    margin 2px 0
    border-radius $border-radius
    background-color white
    background-size contain !important
    cursor pointer
    &--loading
      cursor default
    &--livemode
      cursor default
    .focus-pin
      position absolute
      bottom 170%
      transform translateX(-50%)
    .cursor
      position relative
      z-index 1
      width 9px
      height 100%
      cursor grab
      &--transition
        transition left 0.3s ease
      &:active
        cursor grabbing
      &:after
        position relative
        left 50%
        z-index 2
        display block
        box-sizing border-box
        width 3px
        height 100%
        background-color black
        content ''
        transform translateX(-40%)
    .timeline__spinner, .timeline__text
      position absolute
      top 50%
      left 50%
      transform translate(-50%, -50%)
    .timeline__text
      color black
      text-transform uppercase
      font-weight 700
      font-size $fs-h3
</style>
