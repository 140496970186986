<template>
  <div v-if="showCursorInfo"
       class="cursor-info o-button">
    <div class="cursor-info__mean">
      {{ mean ? mean: $t('components.commons.noData') }}
    </div>
    <div class="cursor-info__content">
      {{ dateFormated }}
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'CursorInfo',
  props: {
    time: {
      type: String,
      default: 'NA',
    },
    showCursorInfo: {
      type: Boolean,
      default: true,
    },
    mean: {
      type: String,
      default: '',
    },
  },
  computed: {
    dateFormated() {
      return moment(this.time)
        .local()
        .format('ddd D MMM YYYY [•] HH[H]mm');
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.cursor-info
  position absolute
  bottom 250%
  left 50%
  background-color black
  color white
  transform translateX(-50%)
  .cursor-info__mean
    position relative
    text-align center
    white-space nowrap
    font-weight 700
    font-size $fs-h2
  .cursor-info__content
    position relative
    white-space nowrap
    font-weight 400
    &:after
      position absolute
      top 100%
      left 50%
      width 0
      height 0
      border-width 20px 15px 0 15px
      border-style solid
      border-color black transparent transparent transparent
      content ''
      transform translateX(-50%)
</style>
