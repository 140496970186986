<template>
  <div>
    <div :style="gradientStyle"
         class="range-container"
         @mouseover="showLabel=true"
         @mouseout="showLabel=false">
      <div v-show="showLabel"
           v-if="gradientTypes.includes(type)"
           class="color-label color-label--max">
        {{$t('components.colorRangeHelper.max')}}
      </div>
      <div v-show="showLabel"
           v-if="gradientTypes.includes(type)"
           class="color-label color-label--no-data">
        {{$t('components.colorRangeHelper.noData')}}
      </div>
      <div v-for="(value) in colorsLabelArray"
           v-if="!gradientTypes.includes(type)"
           :key="value"
           :style="'background-color: ' + value.color"
           class="range-color">
        <transition name="slide-fade">
          <div v-show="showLabel"
               v-if="value.label !== ''"
               class="color-label">
            {{ value.label }}
          </div>
        </transition>
      </div>
      <div v-if="!gradientTypes.includes(type)"
           style="background: url('/assets/ui/PNG/trame.jpg'); width: 20px; height: 20px; margin: auto"
           class="range-color">
        <transition name="slide-fade">
          <div v-show="showLabel"
               class="color-label">
            {{$t('components.colorRangeHelper.noData')}}
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ColorRangeHelper',
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showLabel: false,
      gradientTypes: ['rainbow', 'greyToPrimary', 'heatmap', 'heatmap-rainbow'],
    };
  },
  computed: {
    ...mapState('ui', ['colorScales', 'colorLabels']),
    gradientStyle() {
      if (!this.gradientTypes.includes(this.type)) return '';
      let gradient = '';
      for (const r of this.colorScales[this.type]) {
        if (gradient.length) gradient += ', ';
        gradient += this.hexToRGBA(r);
      }
      return `height: 80px; border: 1px solid black; background-image: linear-gradient(to top, transparent 10%, ${gradient}), url('/assets/ui/PNG/trame.jpg')`;
    },
    currentOapp() {
      return this.$store.state.oapp.currentOapp;
    },
    currentOappLabels() {
      return this.currentOapp.data.global.conf.typeInfo
        ? Object.values(this.currentOapp.data.global.conf.typeInfo[this.type].scaleLabels)
        : null;
    },
    legendLabels() {
      if (this.currentOappLabels) return this.currentOappLabels;
      else if (this.colorLabels[this.type]) return this.colorLabels[this.type];
      else return null;
    },
    colorsLabelArray() {
      return this.colorScales[this.type]
        .slice()
        .reverse()
        .reduce((acc, item, idx) => {
          acc.push({
            color: item,
            label: this.legendLabels
              ? this.$t(`components.colorRangeHelper.oapps.${this.currentOapp.name.toLowerCase()}.${this.legendLabels[idx]}`)
              : '',
          });
          return acc;
        }, []);
    },
  },
  methods: {
    hexToRGBA(hex) {
      const val = hex.replace('#', '');
      const r = parseInt(val.substring(0, 2), 16);
      const g = parseInt(val.substring(2, 4), 16);
      const b = parseInt(val.substring(4, 6), 16);
      return `rgb(${r}, ${g}, ${b})`;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.range-container
  padding 2px 0 2px 0
  width 20px
  background-size contain !important

.range-color
  margin auto
  width 20px
  height 20px
  border-width 1px 1px 0 1px
  border-style solid
  border-color black
  background-size 90px 30px !important
  &:last-child
    border-bottom 1px solid black

.slide-fade-enter-active, .slide-fade-leave-active
  transition all 0.3s ease

.slide-fade-enter, .slide-fade-leave-to
  opacity 0
  transform translateX(10px)

.color-label
  position relative
  display inline-flex
  justify-content center
  align-items center
  margin-left 28px
  padding 2px 8px
  width auto
  border-top-right-radius 10px
  border-bottom-right-radius 10px
  background-color $black
  color white
  white-space nowrap
  font-weight 500
  font-size $fs-body
  &:after
    position absolute
    left -17.5px
    width 0px
    height 0px
    border-top 9.5px solid transparent
    border-right 8px solid $black
    border-bottom 9.5px solid transparent
    border-left 9.5px solid transparent
    content ''
  &--max
    position absolute
    top -5px
  &--no-data
    position absolute
    bottom 0
</style>
