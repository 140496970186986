<template>
  <div @wheel.prevent=""
       @contextmenu.prevent="">
    <color-range-helper v-if="currentOapp && currentOapp.data"
                        :type="currentOapp.type"
                        class="color-helper" />
    <div class="apps-tools">
      <Timeline v-if="(currentOapp && currentOapp.type !== 'heatmap' && currentOapp.type !== 'custom')"
                :timeRange="timeRange"
                :data="data"
                :mean="currentOapp.data ? currentOapp.data.global.total : undefined "
                :limits="limits"
                :liveMode="timeRangeIsLive"
                :loading="fetching" />
    </div>
  </div>
</template>

<script>
import Timeline from '@/app/views/building-viewer/components/timeline.vue';
import colorRangeHelper from '@/app/views/building-viewer/components/color-range-helper.vue';

export default {
  components: {
    Timeline,
    colorRangeHelper,
  },
  computed: {
    currentOapp() {
      return this.$store.state.oapp.currentOapp;
    },
    fetching() {
      return this.$store.state.oapp.fetching;
    },
    timeRange() {
      return {
        dateFrom: this.$store.state.selections.time.from,
        dateTo: this.$store.state.selections.time.to,
      };
    },
    timeRangeIsLive() {
      return this.$store.state.selections.time.range === 'live';
    },
    data() {
      const arrayData = [];
      if (this.currentOapp && this.currentOapp.data)
        for (const timestamp in this.currentOapp.data.timeline) arrayData.push([timestamp, this.currentOapp.data.timeline[timestamp]]);
      return arrayData;
    },
    limits() {
      if (!this.currentOapp || !this.currentOapp.data || !this.currentOapp.type) return { min: 0, max: 0 };
      return {
        min: this.currentOapp.limits[this.currentOapp.type].min,
        max: this.currentOapp.limits[this.currentOapp.type].max,
      };
    },
  },
};
</script>

<style lang="stylus" scoped>
.apps-tools
  position absolute
  right 0
  bottom 0
  left 0
  z-index 2
  display flex
  padding 32px

.color-helper
  position absolute
  bottom 75px
  left 32px
  z-index 2
</style>
